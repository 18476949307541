<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" no-gutters>
      <h2 v-bind:class="mobile ? 'secondTitleMobile' : 'secondTitle'" v-html="$t('secondTitle')"></h2>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'secondSectionMobile' : 'secondSection'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 5">
        <h4 class="titleSecondSection" v-html="$t('title1')"></h4>
        <div class="textSecondSection" v-html="$t('text1')"></div>
      </v-col>
      <v-col cols="2" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 5" v-bind:style="'padding-top: 20px;'">
        <h4 class="titleSecondSection" v-html="$t('title2')"></h4>
        <div class="textSecondSection" v-html="$t('text2')"></div>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <h2 v-bind:class="mobile ? 'secondTitle2Mobile' : 'secondTitle2'" v-html="$t('secondTitle2')"></h2>
    </v-row>
    <v-row justify="start" class="content" style="padding-bottom: 80px" no-gutters v-if="!mobile">
      <v-col cols="12">
        <h3 class="tableHeader" v-html="$t('title3')"></h3>
      </v-col>
      <v-col cols="12" style="margin-top: 20px;margin-bottom: 20px;">
        <v-img
            v-if="$i18n.locale === 'fr'"
            :src="require('@/assets/vitaeHealthHouse/deviceFr.png')"
            contain
            height="800"
        />
        <v-img
            v-else
            :src="require('@/assets/vitaeHealthHouse/deviceEn.png')"
            contain
            height="800"
        />
      </v-col>
      <div class="textSecondSection" v-html="$t('text3')"></div>
    </v-row>
    <v-row justify="center" align="center" v-bind:class="mobile ? 'thirdSection' : 'thirdSection content'" no-gutters>
      <v-col v-bind:cols="mobile ? 12 : 4">
        <v-img
            :src="require('@/assets/vitaeHealthHouse/warning1.svg')"
            contain
            height="100"
        />
        <div v-html="$t('text4')" v-bind:class="mobile ? 'warningTitleMobile' : 'warningTitle'"></div>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 8">
        <div v-html="$t('text5')" v-bind:class="mobile ? 'warningTextMobile' : 'warningText'"></div>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-bind:class="mobile ? '' : 'content pt-14 pb-6'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 12" style="display: flex">
        <v-img
            v-if="!mobile"
            :src="require('@/assets/vitaeHealthHouse/arrow.svg')"
            class="arrow"
            contain
            height="75"
            max-width="75"
        />
        <div v-html="$t('text6')" v-bind:class="mobile ? 'textArrowMobile' : 'textArrow'"></div>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-expansion-panels accordion flat style="z-index: 0;" v-bind:class="mobile ? 'panelMobile' : ''">
        <v-expansion-panel>
          <v-expansion-panel-header color="#D5EEF4" v-bind:class="mobile ? 'expansionTitleMobile' : 'expansionTitle'">
            {{$t('title4')}}<div v-if="mobile"></div>
            <template v-slot:actions>
              <v-icon color="#57939E" v-bind:size="mobile ? 50 : 75">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#D5EEF4" v-bind:class="mobile ? 'pb-5' : 'content pb-10'">
            <v-row justify="center">
              <v-col v-bind:cols="mobile ? 10 : 6" class="textSecondSection" v-bind:style="mobile ? 'text-align: center;' : ''">
                <div v-bind:style="mobile ? 'text-align: left;' : ''" v-html="$t('text7')"></div>
                <v-btn outlined rounded color="#57939E" v-bind:class="mobile ? 'typeMobile' : 'type'" height="60">
                  <div v-html="$t('type1')" v-bind:class="mobile ? 'typeInMobile' : 'typeIn'"></div>
                </v-btn>
                <br>
                <v-btn outlined rounded color="#57939E" v-bind:class="mobile ? 'typeMobile' : 'type'" height="60">
                  <div v-html="$t('type2')" v-bind:class="mobile ? 'typeInMobile' : 'typeIn'"></div>
                </v-btn>
                <br>
                <v-btn outlined rounded color="#57939E" v-bind:class="mobile ? 'typeMobile' : 'type'" height="60">
                  <div v-html="$t('type3')" v-bind:class="mobile ? 'typeInMobile' : 'typeIn'"></div>
                </v-btn>
              </v-col>
              <v-col v-bind:cols="mobile ? 10 : 6">
                <div v-html="$t('text8')" class="textSecondSection"></div>
                <div v-html="$t('text9')" class="textBlue2"></div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header v-bind:class="mobile ? 'expansionTitleMobile' : 'expansionTitle'">
            {{$t('title5')}}<div v-if="mobile"></div>
            <template v-slot:actions>
              <v-icon color="#57939E" v-bind:size="mobile ? 50 : 75">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-bind:class="mobile ? 'pb-5 pt-5' : 'content pb-10'">
            <v-row justify="center">
              <v-col v-bind:cols="mobile ? 10 : 6">
                <div v-html="$t('text10')" class="textSecondSection"></div>
              </v-col>
              <v-col v-bind:cols="mobile ? 10 : 6">
                <div v-html="$t('text11')" class="textBlue2"></div>
                <div v-html="$t('text12')" class="textSecondSection"></div>
                <div v-html="$t('text13')" class="textBlue2"></div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="#D5EEF4" v-bind:class="mobile ? 'expansionTitleMobile' : 'expansionTitle'">
            {{$t('title6')}}<div v-if="mobile"></div>
            <div v-if="mobile"></div>
            <template v-slot:actions>
              <v-icon color="#57939E" v-bind:size="mobile ? 50 : 75">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#D5EEF4" v-bind:class="mobile ? 'pb-5 pt-5' : 'content pb-10'">
            <v-row justify="center" align="center">
              <v-col v-bind:cols="mobile ? 10 : 6">
                <div v-html="$t('text14')" class="textSecondSection"></div>
              </v-col>
              <v-col v-bind:cols="mobile ? 10 : 6">
                <v-img
                    alt="Coach"
                    :src="require('@/assets/vitaeHealthHouse/sport1.png')"
                    contain
                    v-bind:height="mobile ? 250 : 350"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header v-bind:class="mobile ? 'expansionTitleMobile' : 'expansionTitle'">
            {{$t('title7')}}<div v-if="mobile"></div>
            <template v-slot:actions>
              <v-icon color="#57939E" v-bind:size="mobile ? 50 : 75">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-bind:class="mobile ? 'pb-5 pt-5' : 'content pb-10'">
            <v-row justify="center">
              <v-col v-bind:cols="mobile ? 10 : 6">
                <div v-html="$t('text15')" class="textSecondSection"></div>
              </v-col>
              <v-col v-bind:cols="mobile ? 10 : 6">
                <v-img
                    v-if="mobile"
                    alt="Warning"
                    :src="require('@/assets/vitaeHealthHouse/warningBlue.svg')"
                    contain
                    height="40"
                />
                <div v-if="mobile" v-html="$t('type4')" class="warningInMobile"></div>
                <v-btn outlined rounded color="#57939E" class="type2" height="60" v-if="!mobile">
                  <v-img
                      alt="Warning"
                      :src="require('@/assets/vitaeHealthHouse/warningBlue.svg')"
                      contain
                      height="40"
                  />
                  <div v-html="$t('type4')" class="warningIn"></div>
                </v-btn>
                <div v-html="$t('text16')" class="textBlue2"></div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="#D5EEF4" v-bind:class="mobile ? 'expansionTitleMobile' : 'expansionTitle'">
            {{$t('title8')}}<div v-if="mobile"></div>
            <template v-slot:actions>
              <v-icon color="#57939E" v-bind:size="mobile ? 50 : 75">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#D5EEF4" v-bind:class="mobile ? 'pb-5 pt-5' : 'content pb-10'">
            <v-row justify="center" align="center">
              <v-col v-bind:cols="mobile ? 10 : 6">
                <div v-html="$t('text17')" class="textSecondSection"></div>
              </v-col>
              <v-col v-bind:cols="mobile ? 10 : 6">
                <v-img
                    alt="Sport"
                    :src="require('@/assets/vitaeHealthHouse/sport2.png')"
                    contain
                    v-bind:height="mobile ? 250 : 350"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'SportProComp',
    props: ['mobile'],
    data: () => ({
    })
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Sports professionals",
    "secondTitle": "According to the Ministry of Sports, 2 types of population are concerned by the practice of healthy sport. <br> Depending on the case, the supervisors will not have the same profiles and skills:",
    "secondTitle2": "The interministerial instruction of the so-called « sport on prescription » law defines in preferential order the interveners or supervisors authorized to provide physical activity adapted to people with ALD, according to their degree of functional limitation:",
    "type1": "Physiotherapists",
    "type2": "Occupational therapists",
    "type3": "Psychomotor therapists",
    "type4": "The publication of the list of approved <br> AMTs is still awaited.",
    "title1": "For healthy subjects",
    "title2": "For people with chronic illness",
    "title3": "Table of interventions by professionals and other stakeholders",
    "title4": "1 - HEALTHCARE PROFESSIONALS",
    "title5": "2 - TEACHERS IN ADAPTED PHYSICAL ACTIVITY",
    "title6": "3 - SPORTS EDUCATORS",
    "title7": "4 - HOLDERS OF AN APPROVED CQP / TFP",
    "title8": "5 - VOLUNTEERS OR PERSONS HOLDING AN APPROVED FEDERAL CERTIFICATION",
    "text1": "<br> The supervision of physical activity offers intended for people without CD wishing to maintain good health (primary prevention) is not subject to specific regulations. The supervisors are often the same as for sport in general, however we recommend that they acquire the pedagogical and technical skills to take charge of audiences far from physical activity.",
    "text2": "<br> The Sports Health Ordinance law defines the profile and skills of supervisors authorized to provide physical activity adapted to their health. The type of supervisor will depend on the patient's functional limitation. The table below describes precisely the profiles and skills of the authorized supervisors.",
    "text3": "<i> Source: Page 20 of interministerial instruction N ° DGS / EA3 / DGESIP / DS / SG / 2017/81 of 3 March 2017 </i>",
    "text4": "WARNING",
    "text5": "It is the supervisor's responsibility to check if he has the qualifications and skills to supervise a given audience in the health sport sector.",
    "text6": "Here are the 5 profiles that can supervise patients with long-term illness:",
    "text7": "The health professionals authorized to provide physical activity adapted to people affected by ALD, within the limits of their skills and outside the rehabilitation act covered by health insurance are:",
    "text8": "They preferentially work with patients with severe functional limitations. According to the law, they are the only ones able to intervene for this type of limitation in 1st intention. After an assessment, and depending on the patient's progress and his state of health, they can refer their patients to an APA teacher who can take over.",
    "text9": "<br> <strong> 1- </strong> The physical activity offered by <strong> physiotherapist masseurs </strong> is defined by the sports code as follows: physiotherapy assessment and hygienic gymnastics, maintenance or preventive. <br> <br> <strong> 2- </strong> Not subject to the sports code, the physical activity proposed by <strong> occupational therapists </strong> is defined as follows: « assessments, craft activities , play, expression, daily life, leisure or work »<br> <br> <strong> 3- </strong> For <strong> psychomotor therapists </strong>, assessments, relaxation techniques dynamics, gestural education, bodily or plastic expression and rhythmic activities, play, balancing and coordination, using body mediation and gentle movements.",
    "text10": "According to the decree, they are empowered to provide suitable physical activity to people affected by ALD, within the limits of their skills. <br> <br> They preferentially work with patients with moderate functional limitations. <br> <br> However, after an assessment, and depending on the patient's progress and his state of health, healthcare professionals can refer their patients with severe functional limitations to an APA teacher who can take over.",
    "text11": "The APA teacher aims to:",
    "text12": "<br> - Participate in project design by identifying the needs of the populations concerned by Prevention, Rehabilitation and Integration. <br> - Design and organize intervention programs. <br> - Conduct and evaluate programs intervention. <br> - Participate in project management including APA.",
    "text13": "<br> NB: Please note that the STAPS APAS master does not give any supervisory prerogatives but only the License.",
    "text14": "According to the decree, they are authorized to provide physical activity adapted to people affected by ALD, within the limits of their skills. <br> <br> They preferentially work with patients without any functional limitation or with minimal limitations as part of a prescription. However, additional training is recommended, in particular to supervise patients with moderate limitations. <br> <br> The conditions of practice and the limits of the conditions of practice of each diploma are specified in the sports code.",
    "text15": "Only holders of the Professional Qualification Certificate (CQP) or of an approved Professional Title (TFP) are authorized to provide physical activity adapted to people affected by ALD, within the limits of their skills. <br> < br> They preferentially work with patients with no functional limitation or with minimal limitations within the framework of a prescription.",
    "text16": "<br> The conditions of exercise and the limits of the conditions of exercise of each diploma are specified in the sports code.",
    "text17": "Only volunteers holding a federal certification approved by the ministries of sport and health can provide physical activity adapted to people affected by ALD (without limitation or with minimal limitations) within the framework of a medical prescription. br> <br> You will find here the decree listing the federal certifications authorized to provide an adapted physical activity prescribed by the attending physician to patients suffering from a long-term illness. <br> <br> Volunteers cannot supervise physical activity for remuneration."
  },
  "fr": {
    "mainTitle": "Professionnels du sport",
    "secondTitle": "Selon le Ministère des Sports, 2 types de population sont concernés par la pratique sport santé.<br> Selon le cas, les encadrants n’auront pas les mêmes profils et compétences :",
    "secondTitle2": "L’instruction interministérielle de la loi dite du « sport sur ordonnance » définit par ordre préférentiel les intervenants ou encadrants habilités à dispenser une activité physique adaptée à des personnes en ALD, selon leur degré de limitation fonctionnelle",
    "type1": "Les kinésithérapeutes",
    "type2": "Les ergothérapeutes",
    "type3": "Les psychomotriciens",
    "type4": "La publication de la liste des TFP agrées <br> est toujours attendue.",
    "title1": "Pour les sujets sains ",
    "title2": "Pour les personnes atteintes d'une maladie chronique ",
    "title3": "Tableau des interventions de professionnels et autres intervenants :",
    "title4": "1 - LES PROFESSIONNELS DE SANTÉ",
    "title5": "2 - LES ENSEIGNANTS EN ACTIVITÉ PHYSIQUE ADAPTÉE",
    "title6": "3 - LES EDUCATEURS SPORTIFS",
    "title7": "4 - LES DÉTENTEURS D’UN CQP / TFP AGRÉE",
    "title8": "5 - LES BÉNÉVOLES OU PERSONNES DÉTENTEURS D'UNE CERTIFICATION FÉDÉRALE AGRÉEE",
    "text1": "<br>L’encadrement des offres d’activité physique à destination de personnes sans MC souhaitant se maintenir en bonne santé (prévention primaire) n’est pas soumis à une réglementation particulière. Les encadrants sont souvent les mêmes que pour le sport en général, cependant nous leur recommandons d’acquérir les compétences pédagogiques et techniques pour la prise en charge de publics éloignés de l’activité physique.",
    "text2": "<br>La loi Sport Santé sur Ordonnance définit le profil et les compétences des encadrants habilités à dispenser une activité physique adaptée à leur santé. Le type d’encadrant sera en fonction de la limitation fonctionnelle du patient. Le tableau ci-dessous décrit précisément les profils et compétences des encadrants habilités.",
    "text3": "<i>Source : Page 20 de l'instruction interministérielle N°DGS/EA3/DGESIP/DS/SG/2017/81 du 3 mars 2017 </i>",
    "text4": "ATTENTION",
    "text5": "Il est de la responsabilité de l’encadrant de vérifier s’il a les diplômes et les compétences pour encadrer un public donné dans le secteur du sport santé.",
    "text6": "Voici les 5 profils qui peuvent encadrer des patients en situation d’affection longue durée :",
    "text7": "Les professionnels de santé habilités à dispenser une activité physique adaptée à des personnes touchées par une ALD, dans la limite de leurs compétences et hors acte de rééducation pris en charge par l’assurance maladies sont :",
    "text8": "Ils interviennent préférentiellement auprès de patients présentant des limitations fonctionnelles sévères. Selon la loi, ils sont les seuls à pouvoir intervenir pour ce type de limitation en 1ère intention. Après un bilan, et en fonction de la progression du patient et de son état de santé, ils peuvent orienter leurs patients vers un enseignant en APA qui pourra prendre le relais.",
    "text9": "<br><strong>1-</strong> L’activité physique proposée par <strong>les masseurs kinésithérapeutes</strong> est définie par le code du sport de la façon suivante : bilan kinésithérapique et gymnastique hygiénique, d’entretien ou préventive. <br><br><strong>2-</strong> Non soumis au code du sport, l’activité physique proposée par <strong>les ergothérapeutes</strong> est définie de la suivante : « bilans, activités d’artisanat, de jeu, d’expression, de la vie quotidienne, de loisirs ou de travail » <br><br><strong>3-</strong> Pour <strong>les psychomotriciens</strong>, bilans, techniques de relaxation dynamique, d’éducation gestuelle, d’expression corporelle ou plastique et activités rythmiques, de jeu, d’équilibration et de coordination, en utilisant la médiation corporelle et des mouvements doux.",
    "text10": "Selon le décret, ils sont habilités à dispenser une activité physique adaptée à des personnes touchées par une ALD, dans la limite de leurs compétences. <br><br>Ils interviennent préférentiellement auprès de patients présentant des limitations fonctionnelles modérées.<br><br> Cependant après un bilan, et en fonction de la progression du patient et de son état de santé, les professionnels de santé peuvent orienter leurs patients avec des limitations fonctionnelles sévères vers un enseignant en APA qui pourra prendre le relais.",
    "text11": "L’enseignant en APA a pour vocation à : ",
    "text12": "<br>– Participer à la conception de projet en identifiant les besoins des populations concernées par la Prévention, la Réadaptation et l’Intégration.<br> – Concevoir et organiser des programmes d’intervention.<br> – Conduire et évaluer des programmes d’intervention.<br> – Participer au pilotage de projet incluant de L’APA.",
    "text13": "<br>NB : Attention le master STAPS APAS ne donne pas de prérogatives d’encadrement mais uniquement la Licence.",
    "text14": "Selon le décret, ils sont habilités à dispenser une activité physique adaptée à des personnes touchées par une ALD, dans la limite de leurs compétences.<br><br> Ils interviennent préférentiellement auprès de patients ne présentant aucune limitation fonctionnelle ou présentant des limitations minimes dans le cadre d’une prescription. Cependant une formation complémentaire est recommandée, notamment pour encadrer des patients avec des limitations modérées.<br><br> Les conditions d’exercice et les limites des conditions d’exercice de chaque diplôme sont précisées dans le code du sport.",
    "text15": "Seuls les détenteurs du Certificat de Qualification Professionnel (CQP) ou d’un Titre à Finalité Professionnelle (TFP) agréés sont habilités à dispenser une activité physique adaptée à des personnes touchées par une ALD, dans la limite de leurs compétences.<br><br> Ils interviennent préférentiellement auprès de patients ne présentant aucune limitation fonctionnelle ou présentant des limitations minimes dans le cadre d’une prescription.",
    "text16": "<br>Les conditions d’exercice et les limites des conditions d’exercice de chaque diplôme sont précisées dans le code du sport.",
    "text17": "Seuls les bénévoles titulaires d’une certification fédérale agréée par les ministères du sport et de la santé peuvent dispenser une activité physique adaptée à des personnes touchées par une ALD (sans limitation ou avec limitations minimes) dans le cadre d’une prescription médicale.<br><br> Vous retrouverez ici l’arrêté listant les certifications fédérales autorisées à dispenser une activité physique adaptée prescrite par le médecin traitant à des patients atteints d’une affection de longue durée.<br><br> Les bénévoles ne peuvent encadrer une activité physique contre rémunération."
  }
}
</i18n>

<style scoped>

.secondTitle {
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
  padding: 0 100px 60px;
}

.secondTitle2 {
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
  padding: 40px 200px 60px;
}

.secondTitleMobile {
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;
}

.secondTitle2Mobile {
  padding: 40px 15px;
  font-size: 18px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.secondSection {
  background-color: #D5EEF4;
  padding: 40px 200px;
}

.secondSectionMobile {
  font-size: 14px;
  background-color: #D5EEF4;
  padding-bottom: 40px;
  padding-top: 40px;
}

.panelMobile {
  font-size: 14px;
}

.titleSecondSection {
  color: #1579A7;
}

.textSecondSection {
  color: #808080;
}

.tableHeader {
  color: #808080;
}

.thirdSection {
  color: #FFFFFF;
  font-weight: bold;
  font-family: Comfortaa, sans-serif;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #1579A7;
}

.warningTitle {
  padding-top: 40px;
  text-align: center;
  font-size: 32px;
}

.warningTitleMobile {
  padding-top: 20px;
  text-align: center;
  font-size: 23px;
}

.warningText {
  font-size: 24px;
}

.warningTextMobile {
  padding-top: 20px;
  text-align: center;
  font-size: 16px;
}

.textArrow {
  font-family: Comfortaa, sans-serif;
  font-weight: bold;
  font-size: 22px;
  color: #1579A7;
  display: flex;
}

.textArrowMobile {
  padding-top: 40px;
  padding-bottom: 20px;
  font-family: Comfortaa, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #1579A7;
  display: flex;
}

.warningInMobile {
  padding-top: 10px;
  color: #57939E;
  font-weight: 800;
}

.arrow {
  margin-top: -10px;
  margin-right: 15px;
  margin-left: 40px;
}

.expansionTitle {
  padding-left: 200px;
  color: #57939E;
  font-size: 16px;
  font-weight: bolder;
}

.expansionTitleMobile {
  line-height: 20px;
  color: #57939E;
  font-size: 14px;
  font-weight: bolder;
}

.v-expansion-panel-header >>> .v-expansion-panel-header__icon {
  margin-left: 0;
}

.type {
  cursor: text;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  margin-left: 100px;
  margin-top: 40px;
}

.typeMobile {
  margin-top: 20px;
  cursor: text;
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
}

.type2 {
  cursor: text;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
}

.type2Mobile {
  cursor: text;
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
}

.typeIn {
  padding-left: 75px;
  padding-right: 75px;
}

.typeInMobile {
  padding-left: 20px;
  padding-right: 20px;
}

.textBlue2 {
  color: #57939E;
}

</style>
