<template>
  <div>
    <SportProComp v-bind:mobile="mobile"/>
    <FollowUs v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import SportProComp from '@/components/desktop/vitaeHealthHouse/SportProComp'
import FollowUs from "@/components/desktop/FollowUs";

  export default {
    name: 'SportPro',
    props: ['mobile'],
    components: {
      SportProComp,
      FollowUs
    },
    head () {
      return {
        meta: [
          { hid: 'robots', name: 'robots', content: 'noindex' }
        ]
      }
    }
  }
</script>
